var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 24 24" },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 24 24",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        staticStyle: { fill: "#434955" },
        attrs: {
          d: "M8,14c-0.5499997,0-1.020833-0.1958342-1.4124999-0.5875006S6,12.5500002,6,12\n\ts0.1958332-1.020833,0.5875001-1.4125004S7.4500003,10,8,10s1.020834,0.1958323,1.4125004,0.5874996S10,11.4499998,10,12\n\ts-0.1958332,1.020833-0.5874996,1.4124994S8.5500002,14,8,14z M16,14c-0.5500002,0-1.020833-0.1958342-1.4125004-0.5875006\n\tC14.1958332,13.020833,14,12.5500002,14,12s0.1958332-1.020833,0.5874996-1.4125004C14.979167,10.1958323,15.4499998,10,16,10\n\tc0.5499992,0,1.020834,0.1958323,1.4125004,0.5874996S18,11.4499998,18,12s-0.1958332,1.020833-0.5874996,1.4124994\n\tS16.5499992,14,16,14z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }